import React, { useState, useRef, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import testPdf from "../../../../mock_data/opastiTestiPdfTiedosto.pdf";
import _ from "underscore";

const PdfNotice = ({ notice, noticeDisplayTime, containerHeight, containerWidth, ...props }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [PDF, setPDF] = useState(null);
  const [pageWidth, setPageWidth] = useState(100);
  const [pageHeight, setPageHeight] = useState(0);

  const timeouts = useRef([]);

  const containerRef = useRef(null);

  const newTimeout = (callback, milliseconds) => {
    const timeout = window.setTimeout(callback, milliseconds);
    timeouts.current.push(timeout);
  };

  const clearTimeouts = () => {
    const removed = _.map(timeouts.current, (timeout) =>
      window.clearTimeout(timeout)
    );
    timeouts.current = _.difference(timeouts.current, removed);
  };

  const calculateScale = () => {
    if (pageHeight === 0) return 1;

    const pdfPageWidth = pageWidth;
    const pdfPageHeight = pageHeight;

    console.log(`Container height: ${containerHeight}`);
    console.log(`Container width: ${containerWidth}`)
    let scale = Math.min(
      containerHeight / pdfPageHeight,
      containerWidth / pdfPageWidth
    );
    console.log(`calculated scale: ${scale}`);

    if (scale <= 0) return 1;

    return scale;
  };

  useEffect(() => {
    if (notice.data == null) return;
    if ("pdf_uri" in notice.data) {
      setPDF(notice.data.pdf_uri);
      setPageNumber(1);
    }
  }, [notice]);

  // Switch pdf pages
  useEffect(() => {
    if (!numPages) return;
    let newPageNumber = pageNumber >= numPages ? 1 : pageNumber + 1;

    const timeoutPerPage = noticeDisplayTime / numPages;

    console.log(`Page display time: ${timeoutPerPage / 1000} seconds`);

    newTimeout(() => {
      setPageNumber(newPageNumber);
    }, timeoutPerPage);
    return () => {
      clearTimeouts();
    };
  }, [numPages, pageNumber, noticeDisplayTime]);

  const OnDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const OnPageLoadSuccess = (page) => {
    let pageWidth = page._pageInfo.view[2];
    let pageHeight = page._pageInfo.view[3];

    // Rotated pdfs retain their original width / height values, rather than the rotated ones.
    if (page._pageInfo.rotate === 90 || page._pageInfo.rotate === 270) {
      pageWidth = page._pageInfo.view[3];
      pageHeight = page._pageInfo.view[2];
    }

    setPageWidth(pageWidth);
    setPageHeight(pageHeight);

    console.log(`page width: ${pageWidth}\npage height: ${pageHeight}`);
  };

  //console.log("Timeout: " + noticeDisplayTime);

  return (
    <div
      ref={containerRef}
      className={"h-full flex items-center justify-center"}
    >
      <Document
        file={PDF}
        onLoadSuccess={OnDocumentLoadSuccess}
        loading={<PdfMsg msg={"Ladataan..."} />}
        error={<PdfMsg msg={"Tapahtui virhe."} />}
        noData={
          <PdfMsg msg={"PDF tiedostoa ei ole tai sitä ei saatu ladattua."} />
        }
      >
        {numPages && (
          <Page
            style={{
              width: "fit-content",
              height: "fit-content",
            }}
            debug={true}
            scale={calculateScale()}
            pageNumber={pageNumber}
            onLoadSuccess={OnPageLoadSuccess}
            loading={<PdfMsg msg={"Ladataan..."} />}
            error={
              <PdfMsg msg={"Virhe sivun lataamisessa " + pageNumber + "."} />
            }
          />
        )}
      </Document>
    </div>
  );
};

// Error or loading message to display
const PdfMsg = ({ msg }) => {
  return (
    <div className="p-2 text-lg">
      <p>{msg}</p>
    </div>
  );
};

export default PdfNotice;
