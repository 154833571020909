import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import ProgressBar from "./ProgressBar";

const Ad = ({ ...props }) => {
  const [current, setCurrent] = useState(false);

  const ads = useRef([]);
  const durations = useRef([]);
  const playlist = useRef([]);
  const playing = useRef(false);

  // Ref for video element
  const videoRef = useRef(null);
  const imageTimeout = useRef(null);

  const nextAd = useCallback(() => {
    // If playing, skip. When ad ends it will call us.
    if (playing.current) return;
    imageTimeout.current && window.clearTimeout(imageTimeout.current);

    // Check if needs merging.
    if (ads.current !== playlist.current) {
      const existing = _.intersection(playlist.current, ads.current);
      const latest = _.union(existing.current, ads.current);
      playlist.current = latest;
      durations.current = _.pluck(latest, "duration");
    }

    // If playlist is empty - there is not anything to show.
    if (_.isEmpty(playlist.current)) {
      setCurrent(false);
      playing.current = false;
      return;
    }

    // Get next index
    const nextIndex = playlist.current[current.index + 1] ? current.index + 1 : 0;

    if (playlist.current[nextIndex].key === current.key && current.type === "video") {
      videoRef.current.load();
    }
    setCurrent({
      index: nextIndex,
      ...playlist.current[nextIndex],
    });
    playing.current = true;
  }, [current]);

  // Clean up timeouts if unMount
  useEffect(() => {
    return () => {
      window.clearTimeout(imageTimeout.current);
    };
  }, []);

  // Detect new ads
  useEffect(() => {
    ads.current = _.sortBy(props.ads, "key");
    nextAd();
  }, [props.ads, nextAd]);

  // Handle ending, and play next
  const handleEnding = useCallback(() => {
    playing.current = false;
    nextAd();
  }, [nextAd]);

  // Video element
  const video = useCallback(
    (current) => {
      return (
        <video
          muted
          ref={videoRef}
          src={current.media}
          onCanPlayThrough={() => {
            videoRef.current.play();
          }}
          onEnded={() => handleEnding()}
          className="object-cover w-full h-full"
        />
      );
    },
    [handleEnding]
  );

  // Image element
  const image = useCallback(
    (current) => {
      imageTimeout.current = setTimeout(() => {
        handleEnding();
      }, current.duration);
      return <img src={current.media} className="object-cover w-full h-full" alt="" />;
    },
    [handleEnding]
  );

  // For future, if we add new type of media
  const elementSwitch = useMemo(() => {
    switch (current.type) {
      case "video":
        return video(current);
      case "image":
        return image(current);
      default:
        return null;
    }
  }, [current, video, image]);

  return (
    <div className="relative w-full h-full bg-black" >
      <ProgressBar {...{ durations: durations.current, current: current.index }} />
      {elementSwitch}
    </div>
  );
};

Ad.propTypes = {
  ads: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      type: PropTypes.oneOf(["image", "video"]).isRequired,
      media: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      repeats: PropTypes.number,
    })
  ),
};

Ad.defaultProps = {
  ads: [],
};

export default Ad;
