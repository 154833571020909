import React, {useState, useEffect} from "react";

const FETCH_INTERVAL = 1800000; // 1800000 = 30 min

const WeatherWidget = () => {
    const [weatherData, setWeatherData] = useState(null);
  
    useEffect(() => {
      GetWeather();
      const interval = setInterval(GetWeather, FETCH_INTERVAL);

      return () => {
        clearInterval(interval);
      }
    }, []);
  
    const GetWeather = async () => {
      let identifier = window.top.location.hash.substring(1);
      let url = `${process.env.REACT_APP_OPASTI_EXTENSION_ENDPOINT}/v1/screen/${identifier}/weather`;
      // Fetch weather data
      fetch(url, {
        method: "GET",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            setWeatherData(data);
          }
          else {
            console.log('Could not get weather data');
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  
    if (weatherData == null) {
      return <p className="text-md">Säätietoja ei saatavilla</p>;
    }
  
    return (
      <div className="widget px-4">
        {weatherData.data.current.condition.icon && <img src={weatherData.data.current.condition.icon} alt="" className="h-14" />}
        <div className="flex items-top">
          <span>{weatherData.data.current.temp_c}</span>
          <span className="text-xl px-1">&deg;C</span>
          <span className="ml-3">{weatherData.data.location.name}</span>
        </div>
      </div>
    );
  };

export default WeatherWidget;