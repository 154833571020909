import React, { useState, useEffect } from "react";
import { Clock, Italic } from "react-feather";
import moment from "moment";
import 'moment-timezone';

const TimeWidget = () => {
  const [timeString, setTimeString] = useState("");

  useEffect(() => {
    GetTimeString();

    // Update time every five seconds
    const interval = setInterval(GetTimeString, 5000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  const GetTimeString = () => {
    let dateTime = new Date();
    let minutes = dateTime.getMinutes();
    
    // Hours are gotten through a timezone rather than the local time
    let hours = moment.tz("Europe/Helsinki").format('h');
    const localTimeAmOrPm = moment.tz("Europe/Helsinki").format('a');

    if (localTimeAmOrPm === 'pm'){
      if (hours !== 12) {
        hours = (parseFloat(hours) + 12)
      }
    }
    else if (localTimeAmOrPm === 'am'){
      if (hours === 12) {
        hours = (parseFloat(hours) - 12)
      }
    }

    setTimeString(`${hours}:${minutes < 10 ? "0" : ""}${minutes}`);
  };


  return (
    <div className="widget">
      <Clock className="h-8" style={{ paddingTop: "1px", marginRight: "6px" }} />
      <span>{timeString}</span>
    </div>
  );
};

export default TimeWidget;