import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const ResidentList = ({ residentList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [current, setCurrent] = useState(false);
  const scrollInterval = useRef(null);

  useEffect(() => {
    setCurrent(residentList[0]);
    setCurrentIndex(0);
  }, [residentList]);

  useEffect(() => {
    scrollInterval.current = setTimeout(() => {
      const index = (currentIndex + 1) % residentList.length;
      setCurrentIndex(index);
      setCurrent(residentList[index]);
    }, current.duration);
    return () => clearTimeout(scrollInterval.current);
  }, [scrollInterval, currentIndex, residentList, current]);

  return (
    <div className="relative h-full">
      {current && <h1 className="text-3xl font-semibold mb-4">{current.title}</h1>}
      <ul className="list-none">
        {current &&
          current.content.map((resident, index) => (
            <li key={index + "_" + resident} className="text-lg text-nowrap">
              {resident}
            </li>
          ))}
      </ul>
      <span className="absolute bottom-14 right-0">
        {currentIndex + 1} / {residentList.length}
      </span>
    </div>
  );
};

ResidentList.propTypes = {
  residentList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      date: PropTypes.string,
      duration: PropTypes.number,
      content: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

export default ResidentList;
