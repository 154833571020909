import React from "react";
import PropTypes from "prop-types";
import AdPreloader from "./../../../elements/AdPreloader";

const LeftAd = ({ ads, aspectRatio, ...props }) => {
  return (
    <div
      className={"z-40 max-h-full " + (!aspectRatio ? "flex-even" : "")}
      style={{ aspectRatio: aspectRatio != null ? aspectRatio : "auto", width: aspectRatio ? "auto" : "100%" }}
    >
      <AdPreloader ads={ads} />
    </div>
  );
};

LeftAd.propTypes = {
  ads: PropTypes.array,
};

LeftAd.defaultProps = {
  ads: [],
};

export default LeftAd;
