import React from "react";

const List = ({ item, ...props }) => {
  const list = item.item.data;

  return (
    <div className="h-full w-full max-h-full max-w-full text-lg relative overflow-hidden flex flex-col justify-around items-center pl-6">
      <table className="w-full">
        <tbody>
          {list.map((line, lineIndex) => (
            <tr key={lineIndex}>
              {(line.length < 4 ? [...line, Array(4 - line.length).fill("")] : line).map((cell, index) => (
                <td key={index} className="py-0.5 text-left">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
