import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import 'animate.css'
import '../../..//styles/PublicTransport.css'

const PublicTransport = ({ turnover, items, ...props }) => {
  const timeout = useRef(false)
  const [currentItem, setItem] = useState(0)

  const next = useCallback(
    index => {
      if (!items[index]) {
        index = 0
      }
      timeout.current = window.setTimeout(() => { next(index + 1) }, turnover)
      setItem(index)
    },
    [items, turnover]
  )

  useEffect(() => {
    next(0)
    return () => {
      if (typeof timeout.current === 'number') {
        window.clearTimeout(timeout.current)
      }
    }
  }, [next])

  return (
    <div style={{ backgroundColor: '#06304A', height: '110px', minWidth: '500px' }} className='font-body h-full text-white flex flex-1 justify-between items-center overflow-hidden relative'>
      <div className='w-full h-full relative'>
        {items.map((item, index) => {
          let extraClass = ''
          if (currentItem === index) {
            extraClass = ' animate__slideInDown absolute'
          } else if (currentItem === index + 1 || (index === items.length - 1)) {
            extraClass = ' animate__slideOutDown publicTransport absolute'
          } else {
            extraClass = ' hidden absolute'
          }
          return (
            <div key={`${index}-${item}`} className={'w-full h-full font-regular p-9 text-3xl animate__animated flex items-center' + extraClass}>
              <p>
                {item}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

PublicTransport.propTypes = {
  items: PropTypes.array,
  turnover: PropTypes.number
}

PublicTransport.defaultProps = {
  items: [],
  turnover: 5000
}

export default PublicTransport
