import React from 'react'
import PropTypes from 'prop-types'

import QRCode from 'react-qr-code'

const Install = ({ data, ...props }) => {
  return (
    <div
      style={{ backgroundImage: 'url(/media/empty-bg.webp)' }}
      className='h-screen w-screen max-w-full max-h-full overflow-hidden relative flex justify-center items-center bg-cover bg-no-repeat bg-center'
    >
      {data && data.code && (
        <div className='bg-white p-6 rounded-xl shadow flex items-center flex-col gap-3'>
          <h1 className='font-bold text-2xl'>Opasti asennuskoodi</h1>
          <QRCode value={data.code} />
          <span className='text-4xl uppercase'>{data.code}</span>
        </div>
      )}
    </div>
  )
}

Install.propTypes = {
  response: PropTypes.object
}

Install.defaultProps = {
  response: {}
}

export default Install
