import React, { useState, useEffect, useRef, useCallback } from "react";
// import debugData from "../src/mock_data/debugData.json";
import Install from "./Install";
import View from "./views/klinen/View";

const App = (props) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const timeout = useRef(false);

  const request = useCallback(
    ({ notTimeout = true, previous = "", identifier }) => {
      if (notTimeout) return;
      if (timeout.current) clearTimeout(timeout.current);
      window
        .fetch(`${process.env.REACT_APP_OPASTI_EXTENSION_ENDPOINT}/v1/screen/${identifier}?t=${+new Date()}`)
        .then((res) => res.json())
        .then(
          (result) => {
            const resultComp = JSON.stringify(result);
            if (resultComp !== previous) {
              setResponse(result);
            }
            timeout.current = setTimeout(
              () =>
                request({
                  notTimeout: false,
                  previous: resultComp,
                  identifier,
                }),
              60000
            );
          },
          (error) => {
            console.log(error);
            timeout.current = setTimeout(() => request({ notTimeout: false, previous, identifier }), 60000);
          }
        );
    },
    [timeout]
  );

  useEffect(() => {
    if (window.top.location.hash.substring(1)) {
      request({
        notTimeout: false,
        identifier: window.top.location.hash.substring(1),
      });
      return () => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
      };
    } else {
      setError("Tarkista asennus, laitteen / ikkunnan tunnistetta ei löytynyt.");
    }
  }, [request, props.mockData]);

  if (error) {
    return (
      <div
        style={{ backgroundImage: "url(/media/empty-bg.webp)" }}
        className="h-screen w-screen max-w-full max-h-full overflow-hidden relative flex justify-center items-center bg-cover bg-no-repeat bg-center"
      >
        <div>
          <h1 className="text-2xl">
            <span className="font-bold text-6xl leading-normal">Voi ei,</span>
            <br />
            <span>harmittaa aina kun käy näin.</span>
          </h1>
          <p className="mt-6">{error}</p>
        </div>
      </div>
    );
  }

  return response && response.status
    ? {
        installed: <View data={response.data} />,
        install: <Install data={response.data} />,
      }[response.status]
    : null;
};

export default App;
