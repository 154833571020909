import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'underscore'

import './../styles/AdCarousel.css'

function ProgressBar (props) {
  const [durations, setDurations] = useState([])
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    setDurations(props.durations)
    setCurrent(props.current)
  }, [props])

  return (
    <div className={'absolute p-1 w-full flex flex-auto justify-between' + (_.isEmpty(durations) && ' hidden')}>
      {durations.map((duration, index) => {
        let pass = false
        if (index >= current) pass = true
        return (
          <div
            key={durations.length > 1 ? index : +new Date() + index}
            style={{ backgroundColor: 'rgba(209, 213, 219, .3)' }}
            className={'w-full m-1 h-2 rounded-xl shadow-md shadow-inner' + ((index !== current) ? ' opacity-80' : '')}
          >
            <span
              style={{ animationDuration: `${duration}ms` }}
              className={'bg-white h-full block rounded-xl shadow-inner' + ((index === current) ? ' progressBar' : ((!pass) ? ' w-full' : ' w-0'))}
            />
          </div>
        )
      }
      )}
    </div>
  )
}

ProgressBar.propTypes = {
  durations: PropTypes.array
}

ProgressBar.defaultProps = {
  durations: []
}

export default ProgressBar
