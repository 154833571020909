import React from "react";

const Text = ({ item, ...props }) => {
  return (
    <div className="h-full w-full max-h-full max-w-full text-lg relative flex justify-around items-center pl-6">
      <div className="whitespace-pre-line text-left" style={{ height: "max-content" }}>
        {item.item.content}
      </div>
    </div>
  );
};

export default Text;
